import "bootstrap/js/src/index";
import "./../js/script";


const initWidgets = () => {
    const inputFields: NodeListOf<Element> = document.querySelectorAll('.widget input, .widget textarea');
    inputFields.forEach((element: any) => {
        const parent: Element = element.parentElement;
        element.addEventListener('focusin', () => {
            parent.classList.add('active');
        });
        element.addEventListener('focusout', () => {
            parent.classList.remove('active');
            if (element.value.length > 0) {
                parent.classList.add('has-content');
            } else {
                parent.classList.remove('has-content');
            }
        });

        element.addEventListener('input', () => {
            if (element.classList.valid) {
                parent.classList.add('is-invalid');
            } else {
                parent.classList.remove('is-invalid');
            }

        });

        const focusBorderElement: Element = document.createElement('span');
        focusBorderElement.classList.add('focus-border');
        focusBorderElement.innerHTML = '<i></i>';
        element.parentNode.insertBefore(focusBorderElement, element.nextSibling);

        if (element.value.length > 0) {
            parent.classList.add('has-content');
        } else {
            parent.classList.remove('has-content');
        }
    });
};

const moveModals = () => {
    document.querySelectorAll(".modal")
        .forEach((el) => {
            document.body.append(el);
        });
};


document.addEventListener("DOMContentLoaded", function () {
    initWidgets();
    moveModals();
});


