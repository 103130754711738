import './all.js';
import * as $ from 'jquery';


$(document).ready(function () {
    $(".dropdown-toggle").click(function (n) {
        const target = $($(this).data("target"));
        if (target.is(':visible')) {
            target.removeClass('bounceInDown');
            target.addClass('bounceOutUp');
            target.fadeOut();
        } else {
            target.removeClass('bounceOutUp');
            target.addClass('bounceInDown');
            target.fadeIn();
        }
        n.preventDefault()
    });

    $('.toggle').click(function () {
        $(this).toggleClass("is-active");
        const target = $($(this).data("target"));
        target.toggleClass("is-active")
    });
    /*
    $('[data-toggle="collapse"]').click(function () {
        const target = $($(this).data("target"));
        console.log(target);
        $(this).toggleClass('active');
        target.toggleClass('show');
    });*/

    const inputFields = $('.widget input, .widget textarea');
    inputFields.focusin(function () {
        $(this).parent().addClass('active')
    }).focusout(function () {
        const t = $(this).parent();
        t.removeClass('active'),
            $(this).val().length > 0 ? t.addClass('has-content') : t.removeClass('has-content')
    }).bind('input', function () {
        const t = $(this).parent();
        $(this).is(':invalid') ? t.hasClass('is-invalid') || t.addClass('is-invalid') : t.hasClass('is-invalid') && t.removeClass('is-invalid')
    }).each(function () {
        $(this).after('<span class="focus-border"><i></i></span>');
        const t = $(this).parent();
        $(this).val().length > 0 ? t.addClass('has-content') : t.removeClass('has-content')
    });

    let Closed = false;
    $('.hamburger').click(function () {
        if (Closed == true) {
            $(this).removeClass('open');
            $(this).addClass('closed');
            Closed = false;
        } else {
            $(this).removeClass('closed');
            $(this).addClass('open');
            Closed = true;
        }
    });
});
